import { IToaster } from '@intouch/its.essential/app/essential/services/Toaster';
import { Controller } from '@intouch/its.essential/app/essential/decorators/Controller';
import { BaseItem } from '@intouch/its.check.essential/app/check-essential/domain/checklists/BaseItem';
import { IChecklist } from '../../domain/checklists/Checklist';
import { ItemSectionModal } from './modals/ItemSectionModal';
import { IItemSection, ItemSection } from '../../domain/checklists/ItemSection';
import { ICheckApi } from '../../api/CheckApi';
import { IItemInput, ItemInput } from '../../domain/checklists/ItemInput';
import { ItemInputFactory } from '../../domain/checklists/ItemInputFactory';
import { Confirm } from '@intouch/its.essential/app/essential/modals/Confirm';
import { IPageService } from '../../services/PageService';
import { ICheckSession } from '../../services/CheckSession';
import {
    DefaultItemSearchStrategy,
    IItemReferenceFinder,
    ItemReferenceFinder,
    SectionItemSearchStrategy,
} from '../../domain/checklists/item-reference/ItemReferenceFinder';
import { ItemReferenceDependencyWarningModal } from './modals/ItemReferenceDependencyWarningModal';
import { ChecklistDuplicator } from '../../domain/checklists/ChecklistDuplicator';
import { ChecklistService } from '../../services/ChecklistService';
import { IErrorResponse } from '@intouch/its.essential/app/essential/domain/ErrorResponse';
import { IItemReference } from '../../domain/checklists/item-reference/ItemReference';
import { IAccessService } from '@intouch/its.essential/app/essential/services/access/AccessService';
import { TrialUpgradeModal } from './modals/TrialUpgradeModal';
import { SubscriptionPlanUtils } from '../../utils/SubscriptionPlanUtils';
import { ItemMoveModal } from './modals/ItemMoveModal';
import { IItemCalculation, ItemCalculation } from '../../domain/checklists/ItemCalculation';
import { AddLocationModal } from '../../modals/AddLocationModal';
import { IAccessApi } from '@intouch/its.essential/app/essential/api/AccessApi';
import { PagedEntities } from '@intouch/its.essential/app/essential/domain/PagedEntities';
import { ShepherdProvider } from '../../utils/ShepherdProvider';
import { ChecklistHistoryModal } from './modals/ChecklistHistoryModal';
import { QuestionTooltipService } from '../../services/QuestionTooltipService';
import { ChecklistEditService } from '../../services/ChecklistEditService';
import { ItemDetailsProvider } from '../../domain/checklists/ItemDetailsProvider';
import { IScrollService } from '@intouch/its.essential/app/essential/services/ScrollService';
import { ChecklistStatus } from '../../domain/checklists/enums/ChecklistStatus';

/**
 * The checklist controller
 *
 * @param UserService
 * @param $state
 * @constructor
 */
@Controller('its.check.module.checklists', ChecklistEditController.IID, ChecklistEditController)
class ChecklistEditController {
    static IID: string = 'ChecklistEditController';
    static $inject: Array<string> = [
        'iteToaster',
        '$state',
        '$mdDialog',
        '$translate',
        'itcCheckApi',
        '$timeout',
        'itcPageService',
        'itcCheckSession',
        'itcChecklistService',
        'itcChecklistEditService',
        '$sce',
        '$mdMedia',
        'iteAccessService',
        'APPCONFIG',
        '$window',
        '$scope',
        'iteAccessApi',
        'itcQuestionTooltipService',
        'iteScrollService',
        '$document',
    ];

    public groupTypeMap: Array<any> = [
        { type: 'input', name: 'CHECKLISTS.GROUP_TYPE.INPUT' },
        { type: 'display', name: 'CHECKLISTS.GROUP_TYPE.DISPLAY' },
    ];

    public inputTypeMap: Array<any> = !this.accessService.hasFeatureEnabled('check', 'temperature-question')
        ? ItemDetailsProvider.getItemsWithout('temperature')
        : ItemDetailsProvider.getItems();

    public selectedSection: IItemSection;
    public showingSections: boolean = false;
    public addAnother: { value: boolean } = { value: false };
    public listMode: boolean = false;
    public mobileBreak: string = 'gt-sm';
    public sideNavExpanded: boolean = this.media(this.mobileBreak);

    // permissions
    public hasAclAdmin: boolean = false;
    public hasAclCreator: boolean = false;
    public isAdmin: boolean = false;
    public isIntouchUser: boolean = false;
    public uuid: string;
    public saving: boolean = false;
    public checklist: IChecklist;
    public subscriptionPlan: string;
    public editingItem: BaseItem = null;
    private translations: any = {};
    private shepherd: any = ShepherdProvider.getShepherd();
    private previousInlineText: string = null;
    private isSaving: boolean = false;

    constructor(
        private toaster: IToaster,
        private stateService: ng.ui.IStateService,
        private dialog: ng.material.IDialogService,
        private translate: angular.translate.ITranslateService,
        private checkApi: ICheckApi,
        private timeout: ng.ITimeoutService,
        private pageService: IPageService,
        private session: ICheckSession,
        private checklistService: ChecklistService,
        private checklistEditService: ChecklistEditService,
        private sce: ng.ISCEService,
        private media: ng.material.IMedia,
        private accessService: IAccessService,
        private appConfig: any,
        private window: ng.IWindowService,
        private scope: ng.IScope,
        private accessApi: IAccessApi,
        private questionTooltipService: QuestionTooltipService,
        private scrollService: IScrollService,
        private document: ng.IDocumentService
    ) {
        this.translate([
            'CHECKLISTS.CHECKLIST_TOUR.PUBLISH_YOUR_CHECKLIST',
            'CHECKLISTS.CHECKLIST_TOUR.PUBLISH_CHECKLIST_INSTRUCTIONS',
            'ESSENTIAL.NAV.OK',
            'ESSENTIAL.NAV.NEXT',
            'CHECKLISTS.CHECKLIST_TOUR.ADD_A_LOCATION',
            'CHECKLISTS.CHECKLIST_TOUR.ADD_LOCATION',
            'CHECKLISTS.CHECKLIST_TOUR.ADD_LOCATION_INSTRUCTION',
            'CHECKLISTS.CHECKLIST_TOUR.ADD_ITEM_TO_CHECKLIST',
            'CHECKLISTS.CHECKLIST_TOUR.ADD_ITEM_TO_CHECKLIST_INSTRUCTION',
            'CHECKLISTS.CHECKLIST_TOUR.PERFORM_AN_AUDIT',
            'CHECKLISTS.CHECKLIST_TOUR.PERFORM_AN_AUDIT_INSTRUCTIONS',
            'CHECKLISTS.CHECKLIST_TOUR.CHECKLISTS_NEED_ITEMS',
            'CHECKLISTS.CHECKLIST_TOUR.ADD_QUESTIONS',
            'GENERAL.NOT_NOW',
            'GENERAL.PHOTO_REQUIRED',
            'GENERAL.COMMENT_REQUIRED',
            'GENERAL.PHOTO_OR_COMMENT_REQUIRED',
            'GENERAL.PHOTO_AND_COMMENT_REQUIRED',
            'GENERAL.ALWAYS',
            'GENERAL.WHEN_NOT_OK',
            'GENERAL.WHEN_OK',
        ]).then((translations) => {
            this.translations = translations;
        });

        this.scope.$watch(
            () => {
                return this.checklist;
            },
            () => {
                this.showTour(this.checklist);
            }
        );

        if (!this.stateService.params['uuid']) {
            this.stateService.go('home.checklists.list.all');
            this.toaster.warn('CHECKLISTS.ERRORS.UNABLE_TO_LOAD');
            return;
        }
        this.subscribeToEditingItem();
        this.subscribeToChecklist();
        this.subscribeToCancelEditEscKeydownEvent();

        // set permissions
        this.hasAclAdmin = this.session.getToken().getUser().hasAcl('check_admin');
        this.hasAclCreator = this.session.getToken().getUser().hasAcl('checklist_creator');
        this.isAdmin = this.session.getToken().getUser().isAdmin();
        this.isIntouchUser = this.session.getToken().getUser().isIntouchUser();

        if (
            this.checklistService.getChecklist() &&
            this.checklistService.getChecklist().uuid === this.stateService.params['uuid'] &&
            !this.stateService.params['uploaded']
        ) {
            this.setupChecklist();
        } else {
            this.pageService.showLoader(true);
            this.checkApi
                .findChecklist(this.stateService.params['uuid'])
                .then((checklist: IChecklist) => {
                    this.checklistService.setChecklist(checklist);
                    this.setupChecklist();
                })
                .catch(() => {
                    this.toaster.warn('CHECKLISTS.ERRORS.UNABLE_TO_LOAD');
                    this.stateService.go('home.checklists.list.all');
                    return;
                })
                .finally(() => {
                    this.pageService.showLoader(false);
                });
        }

        this.subscriptionPlan = this.accessService.getToken().getProductBySlug('check').subPlan;

        this.scope.$on('$destroy', () => {
            if (this.editingItem) {
                this.checklistEditService.restoreToPreviousSnapshot();
            }
            this.checklistEditService.clearEditingItem();
        });
    }

    public back(): void {
        this.pageService.back();
    }

    public getSectionLabel(section: ItemSection): string {
        let label: string = section.name;
        if (this.checklist.settings.enableItemNumbering) {
            label = this.checklist.getSectionNumber(section) + '. ' + label;
        }
        return label;
    }

    public toggleSideNav(): void {
        this.sideNavExpanded = !this.sideNavExpanded;
    }

    public closeSideNav(): void {
        this.sideNavExpanded = false;
    }

    public showSideNav(): boolean {
        return this.media(this.mobileBreak) || this.sideNavExpanded;
    }

    public showBackdrop(): boolean {
        return this.showSideNav() && !this.media(this.mobileBreak);
    }

    /**
     * Returns if user is allowed to edit
     *
     * @returns {boolean}
     */
    public canEdit(): boolean {
        return (this.hasAclCreator && this.isChecklistCreator()) || this.hasAclAdmin || this.isAdmin;
    }

    /**
     * Returns true if the loader is currently showing
     *
     * @return {boolean}
     */
    public isLoading(): boolean {
        return this.pageService.loaderVisible;
    }

    /**
     * Returns true if the active user created the checklist
     * @returns {boolean}
     */
    public isChecklistCreator(): boolean {
        if (this.checklist) {
            return this.checklist.createdBy.uuid === this.session.getUser().uuid;
        }
        return false;
    }

    /**
     * Sets up a newly loaded checklist
     */
    public setupChecklist(): void {
        if (!this.checklist.sections || this.checklist.sections.length === 0) {
            this.checklist.sections = [];
            this.checklist.sections.push(new ItemSection(this.translate.instant('CHECKLISTS.MAIN_SECTION')));
        }
        this.pageService.setPageTitle(this.checklist.name);
        this.changeSection(this.stateService.params['section']); // use the first section as the active
    }

    public onPublish(publishPromise: ng.IPromise<IChecklist>): void {
        this.saving = true;
        publishPromise
            .then((result: IChecklist) => {
                this.checklist = result;
                this.checklistService.setChecklist(this.checklist);
                this.toaster.success('CHECKLISTS.MESSAGES.NEW_VERSION');
                this.setSelectedSection(this.selectedSection);
            })
            .catch((error) => {
                this.toaster.warn('CHECKLISTS.ERRORS.UNABLE_TO_PUBLISH', error);
            })
            .finally(() => {
                this.saving = false;
            });
    }

    /**
     * Will display the add new item modal - based on the type
     *
     * @param itemType
     * @param section
     * @param insertAtIndex
     */
    public addItem(itemType: string, section?: IItemSection, insertAtIndex?: number): void {
        this.hideTooltipPanel();

        if (!section) {
            section = this.selectedSection;
        }

        if (section) {
            let itemToAdd: ItemInput = <ItemInput>ItemInputFactory.makeItem(itemType, '');
            this.checklistEditService.editItemWithSnapshot(itemToAdd);
            section.addItem(itemToAdd, insertAtIndex);
            this.scrollToEditingItem(itemToAdd.uuid);
        }
    }

    /**
     * Opens history modal
     */
    public openHistoryModal(): void {
        this.dialog.show(ChecklistHistoryModal.instantiate({ locals: { checklistUuid: this.checklist.originalUuid } }));
    }

    /**
     * Show trial upgrade dialog
     *
     */
    public upgradeModal(): void {
        this.translate([
            'CHECKLISTS.TRIAL_ACCOUNT.TITLE_UNLIMITED_QUESTIONS',
            'CHECKLISTS.TRIAL_ACCOUNT.DESCRIPTION',
            'CHECKLISTS.TRIAL_ACCOUNT.UPGRADE',
            'CHECKLISTS.TRIAL_ACCOUNT.CANCEL',
            'CHECKLISTS.TRIAL_ACCOUNT.OK',
            'CHECKLISTS.TRIAL_ACCOUNT.CONTACT_ADMIN',
        ]).then((translations) => {
            this.dialog
                .show(
                    TrialUpgradeModal.instantiate({
                        locals: {
                            title: translations['CHECKLISTS.TRIAL_ACCOUNT.TITLE_UNLIMITED_QUESTIONS'],
                            description: translations['CHECKLISTS.TRIAL_ACCOUNT.DESCRIPTION'],
                            contactAdmin: translations['CHECKLISTS.TRIAL_ACCOUNT.CONTACT_ADMIN'],
                            confirmText: translations['CHECKLISTS.TRIAL_ACCOUNT.UPGRADE'],
                            cancelText: translations['CHECKLISTS.TRIAL_ACCOUNT.CANCEL'],
                            okText: translations['CHECKLISTS.TRIAL_ACCOUNT.OK'],
                            isAdminOrIntouch: this.isAdmin || this.isIntouchUser,
                        },
                    })
                )
                .then((result) => {
                    if (result) {
                        this.window.location.href = this.appConfig.products.urls.access.root + 'settings/subscriptions';
                    }
                });
        });
    }

    public onItemDrop(fromIndex: number, toIndex: number, item: IItemInput): void {
        let rebuiltItem: IItemInput = ItemInputFactory.buildOne(item);

        if (fromIndex === -1) {
            // dragged from out of menu
            this.addItem(item.type, this.selectedSection, toIndex);
        } else if (fromIndex > -1 && fromIndex !== toIndex - 1) {
            fromIndex < toIndex ? (toIndex -= 1) : (toIndex = toIndex);
            const checklist: IChecklist = this.checklist.moveItem(rebuiltItem, toIndex);

            this.save(checklist);
        }
    }

    public onSectionDrop(fromIndex: number, toIndex: number, section: IItemSection): void {
        if (fromIndex <= -1 || toIndex <= -1) {
            return;
        }

        fromIndex < toIndex ? (toIndex -= 1) : (toIndex = toIndex);
        const rebuiltSection: IItemSection = new ItemSection().fromJson(section);
        const checklist: IChecklist = this.checklist.moveSection(rebuiltSection, fromIndex, toIndex);

        this.save(checklist);
    }

    public editItem(item: ItemInput, editTab: string = 'basic'): void {
        this.checklistEditService.editItemWithSnapshot(item, editTab);
    }

    public cancelEdit(): void {
        this.checklistEditService.cancelEdit();
    }

    /**
     * Duplicate an item
     *
     * @param {IItemInput} item
     */
    public duplicateItem(item: IItemInput): void {
        const itemToDuplicate: ItemInput = <ItemInput>item.duplicate();
        this.checklistEditService.saveSnapshot();
        this.selectedSection.addItem(itemToDuplicate);
        this.checklistEditService.editItem(itemToDuplicate);
    }

    public enableInlineEdit(item: BaseItem, event: KeyboardEvent | MouseEvent, id: string = null): void {
        if (this.editingItem) {
            return;
        }
        event.stopPropagation();
        item.inlineEdit = true;
        this.previousInlineText = item.name;
        this.timeout(() => {
            (<any>$('#' + (id ? id : item.uuid))).focus();
        });
    }

    public saveInlineEdit(item: BaseItem): void {
        this.save(this.checklist);
    }

    public handleInlineKeypress(item: BaseItem, event: KeyboardEvent): void {
        // key code for esc
        event.stopPropagation();
        if (event.keyCode === 27) {
            this.cancelInlineEdit(item, event);
            // key code for enter
        } else if (event.keyCode === 13) {
            this.save(this.checklist);
        }
    }

    public cancelInlineEdit(item: BaseItem, event: MouseEvent | KeyboardEvent): void {
        event.stopPropagation();
        item.name = this.previousInlineText;
        item.inlineEdit = false;
    }

    /**
     * Duplicate a section
     *
     * @param {IItemSection} section
     */
    public duplicateSection(section: IItemSection): void {
        this.dialog
            .show(
                ItemSectionModal.instantiate({}, <any>{
                    item: ChecklistDuplicator.duplicateSection(section),
                    checklist: this.checklist,
                    isEdit: false,
                    isDuplicate: true,
                    addAnother: null,
                    sourceItem: section,
                })
            )
            .then((checklist: IChecklist) => {
                this.updateChecklist(checklist);
            });
    }

    /**
     * Will add a new section to this checklist
     */
    public addSection(): void {
        let sectionToAdd: IItemSection = new ItemSection();

        this.dialog
            .show(
                ItemSectionModal.instantiate({}, <any>{
                    item: sectionToAdd,
                    checklist: this.checklist,
                    isEdit: false,
                    addAnother: this.addAnother,
                })
            )
            .then((checklist: IChecklist) => {
                if (checklist) {
                    // go to new section added (manually set due to timeout needed in setSelectedSection function)
                    let sections: Array<IItemSection> = checklist.sections;
                    this.selectedSection = sections[sections.length - 1];

                    this.updateChecklist(checklist);

                    if (this.addAnother.value) {
                        this.addSection();
                    }
                } else {
                    this.handleError('CHECKLISTS.ERRORS.UNABLE_TO_ADD_SECTION');
                }
            })
            .catch(() => {
                this.addAnother.value = false;
            });
    }

    /**
     * Will allow a section to be edited
     *
     * @param section
     */
    public editSection(section: IItemSection): void {
        this.dialog
            .show(
                ItemSectionModal.instantiate({}, <any>{
                    item: section,
                    checklist: this.checklist,
                    isEdit: true,
                    addAnother: null,
                })
            )
            .then((checklist: IChecklist) => {
                if (checklist) {
                    this.updateChecklist(checklist);
                } else {
                    this.handleError('CHECKLISTS.ERRORS.UNABLE_TO_EDIT_SECTION');
                }
            });
    }

    /**
     * Will allow for a section to be removed
     *
     * @param section
     */
    public removeSection(section: IItemSection): void {
        this.translate([
            'CHECKLISTS.MESSAGES.CONFIRM_REMOVE_SECTION',
            'GENERAL.DELETE',
            'CHECKLISTS.MESSAGES.CONFIRM_CANCEL',
            'CHECKLISTS.MESSAGES.DELETE_SECTION',
        ]).then((translations) => {
            this.dialog
                .show(
                    Confirm.instantiate({
                        locals: {
                            title: translations['CHECKLISTS.MESSAGES.DELETE_SECTION'],
                            description: translations['CHECKLISTS.MESSAGES.CONFIRM_REMOVE_SECTION'],
                            confirmText: translations['GENERAL.DELETE'],
                            cancelText: translations['CHECKLISTS.MESSAGES.CONFIRM_CANCEL'],
                            confirmButtonCssClass: 'its-btn--delete',
                        },
                    })
                )
                .then((result) => {
                    if (result) {
                        let referenceFinder: IItemReferenceFinder = new ItemReferenceFinder(
                            new SectionItemSearchStrategy(this.checklist, section)
                        );
                        let dependencies: Array<IItemReference> = referenceFinder.find();
                        if (dependencies && dependencies.length > 0) {
                            this.dialog
                                .show(
                                    ItemReferenceDependencyWarningModal.instantiate({
                                        locals: {
                                            dependencies: dependencies,
                                            checklist: this.checklist,
                                            localizedTitle: 'CHECKLISTS.DELETE_DEPENDENCIES',
                                        },
                                    })
                                )
                                .then((checklist: IChecklist) => {
                                    this.save(
                                        checklist.clone().removeSection(section),
                                        'CHECKLISTS.MESSAGES.SECTION_REMOVED',
                                        'CHECKLISTS.ERRORS.UNABLE_TO_REMOVE_SECTION'
                                    );
                                });
                        } else {
                            this.save(
                                this.checklist.clone().removeSection(section),
                                'CHECKLISTS.MESSAGES.SECTION_REMOVED',
                                'CHECKLISTS.ERRORS.UNABLE_TO_REMOVE_SECTION'
                            );
                        }
                    }
                });
        });
    }

    /**
     * Sets the new active section which in turn filters the listing
     *
     * @param section
     */
    public changeSection(section?: IItemSection): void {
        if (section) {
            this.setSelectedSection(section);
        } else if (this.checklist.sections && this.checklist.sections.length > 0) {
            this.setSelectedSection(this.checklist.sections[0]);
        }
    }

    /**
     * Allows a user to toggle the section listing on and off
     */
    public toggleSectionListing(): void {
        this.showingSections = !this.showingSections;
        this.sideNavExpanded = false;
        this.setSelectedSection(this.selectedSection);
    }

    /**
     * Sets selected section along with the index for tab use
     *
     * @param section
     */
    public setSelectedSection(section: IItemSection): void {
        this.selectedSection = section;
    }

    /**
     * Display html formatted content
     */
    public htmlOutput(additionalInfo: string): string {
        return this.sce.trustAsHtml(additionalInfo);
    }

    /**
     * Indicates if we are editing or adding a checklist
     *
     * @returns {boolean}
     */
    public isEdit(): boolean {
        return !!(this.checklist || <IChecklist>{}).id;
    }

    /**
     * Will enable a disabled item and save the checklist
     *
     * @param item
     */
    public enableItem(item: IItemInput): void {
        this.save(this.checklist.clone().enableItem(item.uuid), null, 'CHECKLISTS.ERRORS.UNABLE_TO_ENABLE_ITEM');
    }

    /**
     * Calculate the current sections total points
     *
     * @returns {number}
     */
    public totalSectionPoints(): number {
        let currentSectionTotal: number = 0;
        if (this.selectedSection) {
            for (let item of this.selectedSection.items) {
                currentSectionTotal += item.points;
            }
        }
        return currentSectionTotal;
    }

    /**
     * Calculate the Checklist total points
     *
     * @returns {number}
     */
    public totalChecklistPoints(): number {
        let totalSectionPoints: number = 0;
        if (this.checklist) {
            for (let section of this.checklist.sections) {
                totalSectionPoints += section.points;
            }
        }
        return totalSectionPoints;
    }

    /**
     * Will disable an item and save the checklist
     *
     * @param item
     *
     */
    public disableItem(item: IItemInput): void {
        let referenceFinder: IItemReferenceFinder = new ItemReferenceFinder(
            new DefaultItemSearchStrategy(this.checklist, item)
        );
        let dependencies: Array<IItemReference> = referenceFinder.find();

        if (dependencies && dependencies.length > 0) {
            this.dialog
                .show(
                    ItemReferenceDependencyWarningModal.instantiate({
                        locals: {
                            dependencies: dependencies,
                            checklist: this.checklist,
                            localizedTitle: 'CHECKLISTS.DELETE_DEPENDENCIES',
                        },
                    })
                )
                .then((checklist: IChecklist) => {
                    this.save(
                        checklist.clone().disableItem(item.uuid),
                        null,
                        'CHECKLISTS.ERRORS.UNABLE_TO_DISABLE_ITEM'
                    );
                });
        } else {
            this.save(this.checklist.clone().disableItem(item.uuid), null, 'CHECKLISTS.ERRORS.UNABLE_TO_DISABLE_ITEM');
        }
    }

    /**
     * Will enable a disabled section and save the checklist
     *
     * @param section
     */
    public enableSection(section: IItemSection): void {
        this.save(
            this.checklist.clone().enableSection(section.uuid),
            null,
            'CHECKLISTS.ERRORS.UNABLE_TO_ENABLE_SECTION'
        );
    }

    /**
     * Will disable a section and save the checklist
     *
     * @param {IItemSection} section
     */
    public disableSection(section: IItemSection): void {
        let referenceFinder: IItemReferenceFinder = new ItemReferenceFinder(
            new SectionItemSearchStrategy(this.checklist, section)
        );
        let dependencies: Array<IItemReference> = referenceFinder.find();

        if (dependencies && dependencies.length > 0) {
            this.dialog
                .show(
                    ItemReferenceDependencyWarningModal.instantiate({
                        locals: {
                            dependencies: dependencies,
                            checklist: this.checklist,
                            localizedTitle: 'CHECKLISTS.DELETE_DEPENDENCIES',
                        },
                    })
                )
                .then((checklist: IChecklist) => {
                    this.save(
                        checklist.clone().disableSection(section.uuid),
                        null,
                        'CHECKLISTS.ERRORS.UNABLE_TO_DISABLE_SECTION'
                    );
                });
        } else {
            this.save(
                this.checklist.clone().disableSection(section.uuid),
                null,
                'CHECKLISTS.ERRORS.UNABLE_TO_DISABLE_SECTION'
            );
        }
    }

    /**
     * Allows a user to save a new version of a checklist to the server
     *
     * @param updatedChecklist - a clone of the current checklist with the new changes to it
     * @param successMessage
     * @param errorMessage
     *
     */
    public save(updatedChecklist: IChecklist, successMessage?: string, errorMessage?: string): ng.IPromise<void> {
        this.isSaving = true;
        return this.checklistEditService
            .saveChecklist(updatedChecklist)
            .then((checklist: IChecklist) => {
                if (successMessage) {
                    this.translate([successMessage]).then((translations) => {
                        this.toaster.success(translations[successMessage]);
                    });
                }
                this.updateChecklist(checklist);
                this.checklistEditService.clearEditingItem();
            })
            .catch((error: IErrorResponse) => {
                if (error && error.type === 'ProposedRevisionAlreadyExistsException') {
                    this.toaster.warn('CHECKLISTS.ERRORS.PROPOSED_REVISION_ALREADY_EXISTS');
                } else {
                    if (errorMessage) {
                        this.translate([errorMessage]).then((translations) => {
                            this.toaster.warn(translations[errorMessage]);
                        });
                    }
                }
            })
            .finally(() => {
                this.isSaving = false;
            });
    }

    /**
     * Will remove the question from the checklist
     *
     * @param item
     */
    public removeItem(item: IItemInput): void {
        this.translate([
            'CHECKLISTS.MESSAGES.DELETE_QUESTION_TEXT',
            'GENERAL.DELETE',
            'CHECKLISTS.MESSAGES.CONFIRM_CANCEL',
            'CHECKLISTS.MESSAGES.DELETE_QUESTION',
        ]).then((translations) => {
            this.dialog
                .show(
                    Confirm.instantiate({
                        locals: {
                            title: translations['CHECKLISTS.MESSAGES.DELETE_QUESTION'],
                            description: translations['CHECKLISTS.MESSAGES.DELETE_QUESTION_TEXT'],
                            confirmText: translations['GENERAL.DELETE'],
                            cancelText: translations['CHECKLISTS.MESSAGES.CONFIRM_CANCEL'],
                            confirmButtonCssClass: 'its-btn--delete',
                        },
                    })
                )
                .then((result) => {
                    if (result) {
                        let referenceFinder: IItemReferenceFinder = new ItemReferenceFinder(
                            new DefaultItemSearchStrategy(this.checklist, item)
                        );
                        let dependencies: Array<IItemReference> = referenceFinder.find();
                        if (dependencies && dependencies.length > 0) {
                            this.dialog
                                .show(
                                    ItemReferenceDependencyWarningModal.instantiate({
                                        locals: {
                                            dependencies: dependencies,
                                            checklist: this.checklist,
                                            localizedTitle: 'CHECKLISTS.DELETE_DEPENDENCIES',
                                        },
                                    })
                                )
                                .then((checklist: IChecklist) => {
                                    this.save(
                                        checklist.clone().removeItem(item),
                                        'CHECKLISTS.MESSAGES.QUESTION_DELETED',
                                        'CHECKLISTS.ERRORS.UNABLE_TO_REMOVE_ITEM'
                                    );
                                });
                        } else {
                            this.save(
                                this.checklist.clone().removeItem(item),
                                'CHECKLISTS.MESSAGES.QUESTION_DELETED',
                                'CHECKLISTS.ERRORS.UNABLE_TO_REMOVE_ITEM'
                            );
                        }
                    }
                });
        });
    }

    /**
     * Displays a modal that can be used to change this checklist settings
     */
    public changeChecklistSettings(): void {
        this.stateService.go('home.checklists.settings.general', { uuid: this.stateService.params['uuid'] });
    }

    public changeChecklistOutcomes(): void {
        this.stateService.go('home.checklists.outcomes', { uuid: this.stateService.params['uuid'] });
    }

    /**
     * Displays a modal that can be used to change this checklist settings
     */
    public printChecklist(): void {
        this.stateService.go('home.checklists.print', { uuid: this.stateService.params['uuid'] });
    }

    public openMoveModal(item: IItemInput, section?: IItemSection): void {
        if (!section) {
            section = this.selectedSection;
        }
        this.dialog
            .show(
                <any>ItemMoveModal.instantiate(
                    {},
                    {
                        item: item,
                        canAddTags: false,
                        checklist: this.checklist,
                        isEdit: true,
                        addAnother: null,
                        section: section,
                    }
                )
            )
            .then((checklist: IChecklist) => {
                this.updateChecklist(checklist);
            });
    }

    public getAdditionalRequirementText(item: IItemInput | IItemCalculation): string {
        let text: string = null;

        if (item instanceof ItemInput || item instanceof ItemCalculation) {
            switch (item.getAdditionalRequirementType()) {
                case 'photo':
                    text = this.translations['GENERAL.PHOTO_REQUIRED'];
                    break;
                case 'comment':
                    text = this.translations['GENERAL.COMMENT_REQUIRED'];
                    break;
                case 'photo-or-comment':
                    text = this.translations['GENERAL.PHOTO_OR_COMMENT_REQUIRED'];
                    break;
                case 'photo-and-comment':
                    text = this.translations['GENERAL.PHOTO_AND_COMMENT_REQUIRED'];
                    break;
                default:
                    break;
            }

            switch (item.getAdditionalRequirementCondition()) {
                case 'always':
                    text += ' - ' + this.translations['GENERAL.ALWAYS'];
                    break;
                case 'not-ok':
                    text += ' - ' + this.translations['GENERAL.WHEN_NOT_OK'];
                    break;
                case 'ok':
                    text += ' - ' + this.translations['GENERAL.WHEN_OK'];
                    break;
                default:
                    break;
            }

            return text;
        }
    }

    /**
     * opens help
     */
    public openHelp(): void {
        this.window.open(
            'https://intouchcheck.zendesk.com/hc/en-us/categories/360001050733-Manage-Checklists',
            '_blank'
        );
    }

    public showTooltipPanel(item: any, event: any): void {
        let btnOnClick: () => void = () => {
            this.addItem(item.type);
        };
        this.questionTooltipService.openQuestionTooltip(item, event, btnOnClick);
    }

    public hideTooltipPanel(): void {
        this.questionTooltipService.cancelExistingQuestionTooltips();
    }

    /**
     * Will display an error to the user and redirect
     *
     * @param errorMessage
     * @param error
     */
    private handleError(errorMessage: string, error?: any): void {
        this.toaster.warn(errorMessage);
        this.stateService.transitionTo('home.checklists.edit', { id: this.checklist.id });
    }

    private subscribeToEditingItem(): void {
        this.checklistEditService.editingItem.subscribe((editingItem) => {
            this.editingItem = editingItem;
            if (editingItem) {
                this.sideNavExpanded = true;
            } else {
                this.sideNavExpanded = false;
            }
        });
    }

    private scrollToEditingItem(itemUuid?: string): void {
        let questionLabelId: string = 'itsSectionItem' + this.editingItem.uuid;

        if (itemUuid) {
            questionLabelId = 'itsSectionItem' + itemUuid;
        }

        this.timeout(() => {
            this.scrollService.scrollTo(questionLabelId);
        }, 500);
    }

    private subscribeToChecklist(): void {
        this.checklistService.checklistObserver.subscribe((checklist) => {
            this.checklist = checklist;
            if (this.checklist) {
                if (this.selectedSection) {
                    const currentSection: IItemSection = _.find(this.checklist.sections, {
                        uuid: this.selectedSection.uuid,
                    });
                    if (currentSection) {
                        this.selectedSection = currentSection;
                    }
                } else {
                    this.selectedSection = this.checklist.sections[0];
                }
            }
        });
    }

    private subscribeToCancelEditEscKeydownEvent(): void {
        const escapeKeyCode: number = 27;
        this.document.on('keydown', (event) => {
            if (event.which === escapeKeyCode && this.editingItem) {
                this.cancelEdit();
                this.scope.$apply();
            }
        });
    }

    /**
     * Updates the checklist to a new version
     *
     * @param {IChecklist} checklist
     */
    private updateChecklist(checklist: IChecklist): void {
        this.checklistEditService.updateToLatestChecklistUrl(checklist, this.selectedSection);
        this.checklistService.setChecklist(checklist);
        this.setSelectedSection(this.selectedSection);
    }

    /**
     * Shows tours based on checklist state
     *
     * uses Shepherd - https://shepherdjs.dev/docs/Step.html
     * @param checklist
     */
    private showTour(checklist: IChecklist): void {
        if (!!this.shepherd && checklist && !this.pageService.loaderVisible) {
            if (!this.accessService.isProductTrial('check')) {
                return;
            }

            let tourSettings: any = this.session.getChecklistTourSettings();

            if (!tourSettings.addItemShown && checklist.isNewChecklist()) {
                this.showAddItemTour();
                tourSettings.addItemShown = true;
            } else if (!tourSettings.publishShown && checklist.isPublishable()) {
                this.showPublishTour();
                tourSettings.publishShown = true;
            } else if (!tourSettings.showPerformAuditsTour && checklist.status === ChecklistStatus.Published) {
                this.showPerformAuditsTour();
                tourSettings.showPerformAuditsTour = true;
            }

            this.session.setChecklistTourSettings(tourSettings);
        }
    }

    private showPublishTour(): void {
        let hasLocations: boolean = false;
        const shepherd: any = this.shepherd;

        this.accessApi
            .fetchAllOpenLocations(this.accessService.getToken().getUser().nodes)
            .then((hierarchies: PagedEntities) => {
                if (hierarchies && hierarchies.getEntities().length > 0) {
                    hasLocations = true;
                }
            })
            .finally(() => {
                this.timeout(() => {
                    const tour: any = new shepherd.Tour({
                        defaultStepOptions: {
                            scrollTo: { behavior: 'smooth', block: 'center' },
                        },
                    });

                    this.registerTourCloseOnScopeDestroy(tour);
                    this.addTourStepPublishChecklist(tour, hasLocations);

                    if (!hasLocations) {
                        tour.addStep({
                            title: this.translations['CHECKLISTS.CHECKLIST_TOUR.ADD_A_LOCATION'],
                            text: this.translations['CHECKLISTS.CHECKLIST_TOUR.ADD_LOCATION_INSTRUCTION'],
                            attachTo: {
                                element: '#itc-publish-button',
                                on: 'bottom',
                            },
                            buttons: [
                                {
                                    action: () => {
                                        tour.complete();
                                    },
                                    text: this.translations['GENERAL.NOT_NOW'],
                                },
                                {
                                    action: () => {
                                        tour.hide();
                                        this.dialog.show(AddLocationModal.instantiate({})).then(() => {
                                            if (this.checklist.isPublishable()) {
                                                this.addTourStepPublishChecklist(tour, false);
                                                tour.next();
                                            } else {
                                                tour.complete();
                                            }
                                        });
                                    },
                                    text: this.translations['CHECKLISTS.CHECKLIST_TOUR.ADD_LOCATION'],
                                },
                            ],
                            id: 'add_item_to_checklist',
                        });
                    }

                    tour.start();
                }, 1500);
            });
    }

    private addTourStepPublishChecklist(tour: any, hasLocations: boolean): void {
        tour.addStep({
            title: this.translations['CHECKLISTS.CHECKLIST_TOUR.PUBLISH_YOUR_CHECKLIST'],
            text: this.translations['CHECKLISTS.CHECKLIST_TOUR.PUBLISH_CHECKLIST_INSTRUCTIONS'],
            attachTo: {
                element: '#itc-publish-button',
                on: 'bottom',
            },
            buttons: [
                {
                    action: () => {
                        if (hasLocations) {
                            tour.complete();
                        } else {
                            tour.next();
                        }
                    },
                    text: hasLocations
                        ? this.translations['ESSENTIAL.NAV.OK']
                        : this.translations['ESSENTIAL.NAV.NEXT'],
                },
            ],
            id: 'publish_checklist',
        });
    }

    private showAddItemTour(): void {
        this.timeout(() => {
            const tour: any = new this.shepherd.Tour({
                defaultStepOptions: {
                    scrollTo: { behavior: 'smooth', block: 'center' },
                },
            });

            this.registerTourCloseOnScopeDestroy(tour);

            tour.addStep({
                title: this.translations['CHECKLISTS.CHECKLIST_TOUR.ADD_QUESTIONS'],
                text: this.translations['CHECKLISTS.CHECKLIST_TOUR.CHECKLISTS_NEED_ITEMS'],
                attachTo: {
                    element: '#questionTypeTourAnchor',
                    on: 'right',
                },
                buttons: [
                    {
                        action: () => {
                            tour.complete();
                            if (this.checklist.isPublishable()) {
                                this.showTour(this.checklist);
                            }
                        },
                        text: this.translations['ESSENTIAL.NAV.OK'],
                    },
                ],
                id: 'add_item_to_checklist',
            });

            tour.start();
        }, 1500);
    }

    private showPerformAuditsTour(): void {
        this.timeout(() => {
            const tour: any = new this.shepherd.Tour({
                defaultStepOptions: {
                    scrollTo: { behavior: 'smooth', block: 'center' },
                },
            });

            this.registerTourCloseOnScopeDestroy(tour);
            tour.addStep({
                title: this.translations['CHECKLISTS.CHECKLIST_TOUR.PERFORM_AN_AUDIT'],
                text: this.translations['CHECKLISTS.CHECKLIST_TOUR.PERFORM_AN_AUDIT_INSTRUCTIONS'],
                attachTo: {
                    element: this.media('gt-sm') ? '#itc-side-nav-perform-audits' : '#ite-main-nav-open-button-mobile',
                    on: 'bottom',
                },
                buttons: [
                    {
                        action: () => {
                            tour.complete();
                        },
                        text: this.translations['ESSENTIAL.NAV.OK'],
                    },
                ],
                id: 'add_item_to_checklist',
            });

            tour.start();
        }, 1500);
    }

    /**
     * Sets up on destory event of scope to close tour
     *
     * @param tour
     */
    private registerTourCloseOnScopeDestroy(tour: any): void {
        this.scope.$on('$destroy', () => {
            if (tour) {
                tour.complete();
            }
        });
    }
}
